/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


var basePath = window.location.protocol + "//" + window.location.host + "/";
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


                  // JavaScript to be fired on all pages
                  jQuery('.zip-ajax-search').submit(zipSubmit);

                  jQuery(".zip-ajax-search").on("submit", function (e) {
                      e.preventDefault();
                  });



                  function zipSubmit(){
                    console.log('zipSubmit');
                    var zipForm = jQuery(this).serialize();
                    jQuery.ajax({
                      type: "POST",
                      url: "/wp-admin/admin-ajax.php",
                      data: zipForm,
                      success: function(data){
                        if(data === ""){
                          window.location.href = basePath + "404.php";
                        }else{
                          window.location.href = data;
                        }
                        
                      }
                    });

                  }

                  jQuery('.contact-us-form').submit(contactSubmit);

                  jQuery('.contact-us-form').on("submit", function (e) {
                      e.preventDefault();
                  });

                  function contactSubmit(){
                    var phoneSubmit = jQuery(".load-submit", this);
                    var phoneResponse = jQuery('.msg-response', this);
                    jQuery(phoneSubmit ).prop('value', 'Submitting...');
                    jQuery(phoneSubmit ).css("background-color", "#012951");  
                    /*var phonenumber = jQuery("input[name='phonenumber']",this).val();
                    var name = jQuery("input[name='name']",this).val();
                    var email = jQuery("input[name='email']",this).val();
                    var message = jQuery("textarea[name='description']",this).val();
                    var sel = jQuery("select option:selected",this).val();*/
                    var contactForm = jQuery(this).serialize();
                    console.log('hey');
                    jQuery.ajax({
                        type: "POST",
                        url: "/wp-admin/admin-ajax.php",
                        data: contactForm,
                        success: function(data){
                            success(phoneSubmit,phoneResponse);
                        }
                    });
                  }


                  
                  function success(x, y){
                        jQuery(x).css("color", "#F6BB09");
                        jQuery(x).prop('value', 'We will be in touch shortly');
                        jQuery(x).attr('disabled', true);
                        jQuery(y).text("Your request was sent successfully!");
                    }

                  function phoneSubmit(){
                    var phoneSubmit = jQuery(".load-submit", this);
                    var phoneNumber = jQuery('[name = "phonenumber"]', this).val();
                    var phoneResponse = jQuery('.msg-response', this);
                    jQuery(phoneSubmit ).prop('value', 'Submitting...');
                    jQuery(phoneSubmit ).attr('disabled', true);
                    jQuery(phoneResponse).text("");
                    jQuery(phoneSubmit ).css("background-color", "#012951");  
                    var phoneForm = jQuery(this).serialize();
                    var time = new Date();
                    var urlFrom = window.location.pathname;
                    if(jQuery(this).hasClass("modal-phone")){
                        urlFrom = "modal " + urlFrom; 
                    }
                    var phoneFilter = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
                    if(phoneFilter.test(phoneNumber)){
                          jQuery.ajax({
                            type: "POST",
                            url: "/wp-admin/admin-ajax.php",
                            data: phoneForm,
                            success: function(data){
                                // Email sent
                            }
                          }),
                          jQuery.ajax({
                              type: "POST",
                              url: "https://script.google.com/macros/s/AKfycbyt5dDTwysS5QITkeCdPTH-zSPhMF6bGr2i7HSaZfphXMgOb-WI/exec",
                              data: {"URL FROM" : urlFrom, "PHONE NUMBER" : phoneNumber},
                              dataType: "xml",
                              statusCode: {
                                        0: function (){
                                            // Sent to Google Doc
                                            success(phoneSubmit, phoneResponse);
                                        },
                                        200: function (){
                                            // Sent to Google Doc
                                            success(phoneSubmit, phoneResponse);
                                        }
                                }    
                            });
                    }else{
                        jQuery(phoneSubmit).attr('disabled', false);
                        jQuery(phoneSubmit).css("background-color", "#ec465a");
                        jQuery(phoneSubmit).prop('value', 'Request for a quick call back!');
                        jQuery(phoneResponse).text("Please enter a valid phone number and try again");
                    }
                  }
                    jQuery('.email-ajax-phonenumber').submit(phoneSubmit);

                  jQuery('.email-ajax-phonenumber').on("submit", function (e) {
                      console.log('prevent');
                      e.preventDefault();
                  });
                    

                    // function success(x, y){
                    //     jQuery(x).css("color", "#F6BB09");
                    //     jQuery(x).prop('value', 'We will be in touch shortly');
                    //     jQuery(x).attr('disabled', true);
                    //     jQuery(y).text("Your request was sent successfully!");
                    // }
                                    
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Counter
    'counter': {
      init: function() {
        // JavaScript to be fired on the counter page
        window.onload=function(){
          var amount = document.getElementById('amount');
          var start = new Date("October 1, 2013 00:00:00");
              console.log('hi');
          var current;
          update();

          setInterval(update,1000);
          
          function update() {
            console.log('hi');
            var current = ((new Date()-start)/(24*3600*1000));
            current = current * 3000000;
              console.log(current);  
            amount.innerText = formatMoney(current);
          }

          
          function formatMoney(amount) {
            var dollars = Math.floor(amount).toString().split('');
            var cents = (Math.round((amount%1)*100)/100).toString().split('.')[1];
            if(typeof cents == 'undefined'){
                cents = '00';
            }else if(cents.length == 1){
                cents = cents + '0';
            }
            var str = '';
            for(i=dollars.length-1; i>=0; i--){
                str += dollars.splice(0,1);
                if(i%3 == 0 && i != 0) str += ',';
            }
            return '$' + str;
          }
          //setInterval(update,1000);
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_state': {
      init: function() {
        // JavaScript to be fired on the single state page
        jQuery('#searchforcity').submit(citySubmit);

        jQuery("#searchforcity").on("submit", function (e) {
            e.preventDefault();
        }); 

        function citySubmit(){
       
          var cityForm = jQuery(this).serialize();
          jQuery.ajax({
            type: "POST",
            url: "/wp-admin/admin-ajax.php",
            data: cityForm,
            success: function(data){
              if(data === ""){
                window.location.href = basePath + "404.php";
              }else{
                window.location.href = data;
              }
            }
          });

        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_city': {
      init: function() {
        console.log("hey");
        // JavaScript to be fired on the about us page
        jQuery("#isMoreButton").click(function(e){
                e.preventDefault();
                jQuery("#isMoreButton").css("display","none");
                jQuery("#isLessButton").css("display","block");
                jQuery("#moreCenters").css("display","block");
            });
        jQuery("#isLessButton").click(function(e){
                e.preventDefault();
                jQuery("#isMoreButton").css("display","block");
                jQuery("#isLessButton").css("display","none");
                jQuery("#moreCenters").css("display","none");
            });
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
